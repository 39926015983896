module controllers {
    export module applicationcore {

        interface ITerminalUpdateCtrlScope extends ng.IScope {
        }

        interface ITerminalUpdateParams extends ng.ui.IStateParamsService {
             terminalId: number;
        }

        export class terminalCodeUpdateCtrl {

            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                "$uibModal",
                "$state",
                "$stateParams",
                "$transitions",
                '$anchorScroll',
                "TerminalCodeService"
            ];

         

            ctrlName: string = "Terminal update";
            terminalId: number;

            terminalMaster: interfaces.applicationcore.TerminalCode;
            breadCrumbDesc: string;
            public isLoadingDetail: boolean = false;

          
            constructor(private $scope: ITerminalUpdateCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private $state: ng.ui.IStateService,
                private $stateParams: ITerminalUpdateParams,
                private $transitions: ng.ui.core.ITransition,
                private $anchorScroll: ng.IAnchorScrollService,
                private terminalCodeService: interfaces.applicationcore.ITerminalCodeService,
            ) {
                this.terminalId = $stateParams.terminalId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadTerminal(this.terminalId));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'terminal.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });


            }

            loadTerminal(terminalId: number){
                return this.terminalCodeService.GetTerminalCode(terminalId).query((data: interfaces.applicationcore.TerminalCode) => {

                    this.terminalMaster = data;

                    this.breadCrumbDesc = this.terminalMaster.terminalCode + " - " + this.terminalMaster.TerminalName;

                    this.isLoadingDetail = true;
                    this.$timeout(() => {                    
                        this.isLoadingDetail = false;
                    });


                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }


            SaveTerminal(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                this.terminalCodeService.save().save(this.terminalMaster, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);
   
                   deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'terminal.update'
                },
                    () => {
                        return this.SaveTerminal().then((data: boolean) => {
                            if (data) {
                                this.loadTerminal(this.terminalId);
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.MasterData.Terminal.Update", { terminalId: this.terminalId });
                            }
                        });
                    });

            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ves.update'
                },
                    () => {
                        return this.SaveTerminal().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }


        }

         angular.module("app").controller("terminalCodeUpdateCtrl", controllers.applicationcore.terminalCodeUpdateCtrl);    
    }
}